import * as React from "react"
import Layout from "../../component/layout.js"
import { graphql, Link } from 'gatsby'
import { Helmet } from "react-helmet"
import favicon from "../../images/icon.png"
import * as blogStyle from './blog-template.module.css'
import leftArrow from '../../images/arrow-left-solid.svg'

const BlogTemplate = ({pageContext, data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const mainImg = (frontmatter.mainScreen)? frontmatter.mainScreen.publicURL: frontmatter.image.publicURL;
  return (
    <Layout>
    <Helmet>
      <title>{frontmatter.title} - Barton White's Portfolio</title>
      <link rel="icon" href={favicon} />">
    </Helmet>
    <section id="mainBody">
      <div className="container">
          <div className="twelve columns">
          <h1>{frontmatter.title}</h1>
          <img className={blogStyle.mainImg} src={mainImg} alt="" />
          {data.html}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {frontmatter.link !== '#' &&
            <a className="button button-primary" href={frontmatter.link} target="_blank" rel="noreferrer">Visit the site</a>}
          <p>
            <Link to="/#projects" className="fancyLink"><img src={leftArrow} className="img-icon" alt="" /> Back to Homepage</Link>
          </p>
        </div>
      </div>
    </section>
    </Layout>
    )
}

export default BlogTemplate

export const query = graphql`
query BlogTemplateQuery($slug:String){
  markdownRemark(frontmatter: {slug: {eq: $slug} }) {
    id
    frontmatter {
      color
      slug
      title
      link
      image {
        publicURL
      }
      mainScreen{
        publicURL
      }
    }
    html
  }
}`